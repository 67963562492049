import React from 'react'

const CheckoutSuccess = () => {
  return (
    <h2>
      CheckOut Success
    </h2>
  )
}

export default CheckoutSuccess;
